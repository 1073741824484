import React from "react";

class Vpn extends React.Component {
    render() {
        return(
    <div>

<header class="transparent scroll-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="de-flex sm-pt10">
                            <div class="de-flex-col">
                                <div class="de-flex-col">
                                    <div id="logo">
                                        <a href="https://polygium.com">
                                            <img alt="" src="assets/images/logo.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="de-flex-col header-col-mid">
                                <ul id="mainmenu">
                                    <li>
                                    <a href="https://polygium.com/vpn/doh">DNS over HTTPS<span></span></a>
                                    </li>  									
                                </ul>
                                <div class="menu_side_area">
                                    <span id="menu-btn"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <section id="section-hero" aria-label="section" class="pt20 pb20 vh-100" data-bgimage="url(assets/images/background/11.jpg) top">
                <div id="particles-js"></div>
                <div class="v-center">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="spacer-single"></div>
                                <h6 class="wow fadeInUp" data-wow-delay=".5s"><span class="text-uppercase id-color-2">Polygium</span></h6>
                                <div class="spacer-10"></div>
                                <h1 class="wow fadeInUp" data-wow-delay=".75s">VPN</h1>
                                <p class="wow fadeInUp lead" data-wow-delay="1s">
                                We believe in open, free, and uncensored network and communication.</p>
                                <div class="spacer-10"></div>
                                <a href="#vpn" class="btn-main wow fadeInUp lead" data-wow-delay="1.25s">How Does it Works</a>                             
                                <div class="row">
                                    <div class="spacer-single"></div>
 
                                </div>
                            </div>
                            <div class="col-md-6 xs-hide">
                                <img src="assets/images/shape/logo-light.png" class="img-fluid wow fadeInUp" data-wow-delay=".75s" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        <section aria-label="section" id="vpn">
                <div class="container">
                    <div class="row">
                    <div class="col-md-4 mb60">
                            <i class="fa icon_shield_alt"></i>
							<h3>OPENVPN Client</h3>
							<p>OpenVPN is a virtual private network system that applies techniques to establish secure point-to-point or site-to-site connections in routed or bridged configurations and remote access facilities. It implements both client and server applications.

							</p>
							<div class="spacer10"></div>
						
							<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#openvpn">
							  SHOW<i class="fa arrow_carrot-right"></i>
							</button>

							<div class="modal fade" id="openvpn" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="openvpnBackdropLabel" aria-hidden="true">
							  <div class="modal-dialog">
							    <div class="modal-content">
							      <div class="modal-header">
							        <h5 class="modal-title" id="openvpnBackdropLabel">OPENVPN Client</h5>
							        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							      </div>
							      <div class="modal-body">
                                  <p>Hostname: vpn.polygium.com</p>
						          <a href="https://polygium.com/vpn/tcp_443.zip">
                                  <strong>OpenVPN Configuration File: vpn.polygium.com&nbsp;(TCP 443)</strong>
                                  <img height="29" src="https://cdn.jsdelivr.net/gh/rizky-raditya/wificloudns@master/assets/img/file_openvpn.jpg" width="86" /></a>
							      </div>
							      <div class="modal-footer">
							        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							      </div>
							    </div>
							  </div>
							</div>
						
						</div>
						
						<div class="col-md-4 mb60">
                            <i class="fa icon_shield_alt"></i>
							<h3>L2TP VPN Client</h3>
							<p>Layer 2 Tunneling Protocol is a tunneling protocol used to support virtual private networks or as part of service delivery by ISPs. It uses encryption only for its own control messages, and does not provide any encryption or confidentiality of content by itself.

							</p>
							<div class="spacer10"></div>
						
							<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#l2tp">
							  SHOW<i class="fa arrow_carrot-right"></i>
							</button>

							<div class="modal fade" id="l2tp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="l2tpBackdropLabel" aria-hidden="true">
							  <div class="modal-dialog">
							    <div class="modal-content">
							      <div class="modal-header">
							        <h5 class="modal-title" id="l2tpBackdropLabel">L2TP VPN Client</h5>
							        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							      </div>
							      <div class="modal-body">
                                  <p>Hostname: vpn.polygium.com</p>
						          <p>Username: vpn</p>
						          <p>Password: vpn</p>
						         <p>Pre-shared Key (Secret): vpn</p>
							      </div>
							      <div class="modal-footer">
							        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							      </div>
							    </div>
							  </div>
							</div>
						
						</div>

                        <div class="col-md-4 mb60">
                            <i class="fa icon_shield_alt"></i>
							<h3>SSTP VPN Client</h3>
							<p>Secure Socket Tunneling Protocol is a form of virtual private network tunnel that provides a mechanism for transporting PPP traffic over SSL/TLS channels. SSL/TLS provides transport-level security with key negotiation, encryption, and traffic integrity checks.

							</p>
							<div class="spacer10"></div>
						
							<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#sstp">
							  SHOW<i class="fa arrow_carrot-right"></i>
							</button>

							<div class="modal fade" id="sstp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="sstpdropLabel" aria-hidden="true">
							  <div class="modal-dialog">
							    <div class="modal-content">
							      <div class="modal-header">
							        <h5 class="modal-title" id="sstpdropLabel">SSTP VPN Client</h5>
							        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							      </div>
							      <div class="modal-body">
                                  <p>Hostname: vpn.polygium.com</p>
						          <p>Username: vpn</p>
						          <p>Password: vpn</p>
						          <p>Port : 443</p>
							      </div>
							      <div class="modal-footer">
							        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							      </div>
							    </div>
							  </div>
							</div>
						
						</div>
						
						

                    </div>
                </div>
            </section>
       

        <footer class="footer-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Polygium NFTs</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs Collect</a></li>
                                <li><a href="https://nft.polygium.com/explore">NFTs Explore</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Services</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs</a></li>
                                <li><a href="https://iptv.polygium.com">IPTV</a></li>
                                <li><a href="https://polygium.com/vpn">VPN</a></li>
                                <li><a href="https://storage.polygium.com">FILES</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i> Facebook</a></li>
                                <li><a href="https://twitter.com/polygium/"><i class="fa fa-twitter fa-lg"></i> Twitter</a></li>
                                <li><a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i> Instagram</a></li>
                            </ul>
                        </div>
                    </div>
				<div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
						<h5>About</h5>
                            <p>Projects that are developing with the community for the community.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subfooter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="de-flex">
                                <div class="de-flex-col">
                                    <a href="https://polygium.com">
                                        <span class="copy"> 2022 &copy; <strong><span>Polygium</span></strong></span>
                                    </a>
                                </div>
                                <div class="de-flex-col">
                                    <div class="social-icons">
                                        <a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i></a>
                                        <a><i class="fa fa-twitter fa-lg"></i></a>
                                        <a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


</div>
    
        )
    }
}

export default Vpn;
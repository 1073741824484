import React from "react";

class Iptv extends React.Component {
    render() {
        return(
    <div>

<header class="transparent scroll-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="de-flex sm-pt10">
                            <div class="de-flex-col">
                                <div class="de-flex-col">
                                    <div id="logo">
                                        <a href="https://polygium.com">
                                            <img alt="" src="assets/images/logo.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="de-flex-col header-col-mid">
                                <ul id="mainmenu">
                                    <li>
                                        <a href="https://iptv.polygium.com/channel">CHANNEL TV<span></span></a>
                                    </li> 
                                    <li>
                                        <a href="https://iptv.polygium.com/movies">MOVIES<span></span></a>
                                    </li> 									
                                </ul>
                                <div class="menu_side_area">
                                    <span id="menu-btn"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div id="top"></div>
            <section id="section-hero" aria-label="section" class="pt20 pb20 vh-100" data-bgimage="url(assets/images/background/9.jpg) bottom">
                <div id="particles-js"></div>
                <div class="v-center">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="spacer-10"></div>
                                <h1 class="wow fadeInUp" data-wow-delay=".75s">Polygium IPTV.</h1>
                                <p class="wow fadeInUp lead" data-wow-delay="1s">
                                Live TV Streaming and Watching Movies</p>
                                <div class="spacer-10"></div>
                                <a href="http://play.polygium.com/#/ipfs/" class="btn-main wow fadeInUp lead" data-wow-delay="1.25s">Watching</a>                                
                                <div class="row">
                                    <div class="spacer-single"></div>
                                    <div class="row">
                                            <div class="col-lg-4 col-md-6 col-sm-4 wow fadeInRight mb30" data-wow-delay="1.1s">
                                                <div class="de_count text-left">
                                                    <h3><span>110</span></h3>
                                                    <h5 class="id-color">Channel</h5>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-4 wow fadeInRight mb30" data-wow-delay="1.4s">
                                                <div class="de_count text-left">
                                                    <h3><span>15</span></h3>
                                                    <h5 class="id-color">Movies</h5>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section aria-label="section">
				<div class="container">
					<div class="row">

						<div class="col-md-6">
                            <div class="accordion" id="accordionPanelsStayOpenExample">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                  Cara Memutar Video
                                  </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                  <div class="accordion-body">
                                  Anda dapat menonton video langsung melalui jaringan peer-to-peer yang menyimpan dan berbagi data dalam sistem file terdistribusi, Anda memerlukan Pengalamatan konten atau CIDs untuk mengidentifikasi setiap file video, Anda dapat memutar video dengan mengakses http://play.polygium.com/#/ipfs/"CIDs)".
                                  </div>
                                </div>
                              </div>
                            </div>

                        </div>

                        <div class="col-md-6">
                            <div class="accordion" id="accordionPanelsStayOpenExample">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    Apa itu CIDs
                                  </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                  <div class="accordion-body">
                                  Pengidentifikasi konten, atau CID, adalah semacam alamat berdasarkan hash kriptografis dari konten itu sendiri, Anda bisa mendapatkan konten CID Anda sendiri melalui https://storage.polygium.com atau penyedia penyimpanan terdesentralisasi seperti Pinata, Fleek, Temporal, dan Lainnya.
                                  </div>
                                </div>
                              </div>
                            </div>

                        </div>
					</div>

				</div>
			</section> 

            <section id="section-collections" class="pt30 pb30">
                <div class="container">
                        <div class="row wow fadeIn"> 
                            <div class="text-center">
                                <h2>Channel TV</h2>
                                <div class="small-border bg-color-2"></div>
                            </div>

                                <div id="items-carousel" class="owl-carousel wow fadeIn">

                                <div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/rcti.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Rcti</h4></a>
                                    </div>
                                </div>
                            
                                <div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/gtv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Global TV</h4></a>
                                    </div>
                                </div>
                            
                                <div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/inews.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>iNews</h4></a>
                                    </div>
                                </div>
                            
                                <div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tvone.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Tv One</h4></a>
                                    </div>
                                </div>

                                <div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/transtv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Trans TV</h4></a>
                                    </div>
                                </div>       

							        <div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/mnctv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>MNC TV</h4></a>
                                    </div>
                                </div>	
								
									<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/metrotv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Metro TV</h4></a>
                                    </div>
                                </div>	
                                
									<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/trans7.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Trans 7</h4></a>
                                    </div>
                                </div>

								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/antv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Antv</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tvri.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Tvri</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/nickjr.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Nick Jr.</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/miaomi.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Miao Mi</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/ent.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Entertainment</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/fightsport.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Fight Sports</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/infotainment.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Infotainment</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/fmn.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>FMN</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/babytv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Baby TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cartoonnetwork.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Cartoon Network</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/animalplanet.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Animal Planet</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/afn.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Asian Food Network</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/kidstv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Kids TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/nick.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Nick</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/nationalgeographic.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>National Geographic</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cnbc.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>CNBC</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/life.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Life</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/hits.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>HITS</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/warnertv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Warner TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/crimeinvestigation.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Crime Investigation</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/muslimtv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Muslim TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/musictv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Music TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/mncnews.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>MNC News</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/mncsports.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>MNC Sports</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/idxchannel.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>IDX Channel</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/abcaustralia.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>ABC Australia</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/aljazeera.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Aljazeera English</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cna.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Channel News Asia</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/lifetime.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Lifetime</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/axn.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>AXN</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/animax.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Animax</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/one.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>One</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/natgeowild.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Nat Geo Wild</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/mshopsupersale.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>MShop Super Sale</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/france24.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>France 24</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/discovery.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Discovery Channel</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/history.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>History</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/beinsports1.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Bein Sports 1</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/beinsports3.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Bein Sports 3</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cnn.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>CNN</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/soccerchannel.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Soccer Channel</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/foxnewschannel.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Fox News Channel</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/oktv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>OK TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/thrill.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Thrill</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/celestialmovie.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Celestial Movies</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/arirang.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Arirang</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/zeebioskop.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Zee Bioskop</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/dw.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>DW</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/bbcearth.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>BBC Earth</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/bbcworldnews.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>BBC World News</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/beritasatu.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Berita Satu</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/bloomberg.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Bloomberg</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cbeebies.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Cbeebies</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/ccm.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Celestial Classic Movie</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/jaktv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Jak TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/kix.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>KIX</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/nhkworldpremium.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>NHK World Premium</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tlc.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>TLC</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/besmart.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>BeSmart</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/euronews.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Euronews</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/hgtv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>HGTV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cinemaworld.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Cinema World</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/daaitv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>DAAI TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/net.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Net.</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/ewtn.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>EWTN</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/jtv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>JTV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/balitv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Bali TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/trtworld.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>TRT World</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/rtenglish.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>RT English</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/rtv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>RTV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/kompastv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Kompas TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/nhkworldjapan.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>NHK World Japan</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/mshopsignature.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>MShop Signature</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tv9.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>TV9</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/boomerang.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Boomerang</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/hitsmovie.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Hits Movie</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/aqak.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Al Quraan Al Kareem</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tvn.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>tvN</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tvnmovie.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>tvN Movies</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/lifestylefashion.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Lifestyle & Fashion</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/jiangsutv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Jiangsu TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/hunantv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Hunan TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/sanghaidragontv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Shanghai Dragon TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/xingkongtv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Xing Kong TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/anhuitv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Anhui TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tvmu.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>TVMu</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/hbo.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>HBO</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/hbofamily.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>HBO Family</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/hbosignature.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>HBO Signature</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/hbohits.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>HBO Hits</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cinemax.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Cinemax</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tawaftv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Tawaf TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/visionprime.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Vision Prime</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/mncsports2.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>MNC Sports 2</h4></a>
                                    </div>
                                </div>
																
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/tv5monde.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>TV5 Monde</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cgtn.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>CGTN</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/cgtndocumentary.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>CGTN Documentary</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/bandungtv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Bandung TV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/onchannel.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>ON Channel</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/seatoday.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>SEA Today</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/mncsports3.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>MNC Sports 3</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/dwchannel.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>DW Channel</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/ligafutsalpro.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>Liga Futsal Profesional</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/spotv.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>SPOTV</h4></a>
                                    </div>
                                </div>
								
								<div class="nft_coll">
                                    <div class="nft_wrap">
                                        <a><img src="https://iptv.polygium.com/images/channel/spotv2.png" class="lazy img-fluid" alt=""/></a>
                                    </div>
                                    <div class="nft_coll_info">
                                        <a><h4>SPOTV 2</h4></a>
                                    </div>
                                </div>


                                </div>                                
                            </div>

                            <div class="spacer-single"></div>
                            <div class="row">
                            <div class="text-center">
                                <h2>Movies</h2>
                                <div class="small-border bg-color-2"></div>
                            </div>
                                <div id="collection-carousel-alt" class="owl-carousel wow fadeIn">

                                <div class="nft_coll style-2">
                                            <div class="nft_wrap">
                                                <a><img src="https://iptv.polygium.com/images/movie/1.jpg" class="lazy img-fluid" alt=""/></a>
                                            </div>
                                            <div class="nft_coll_info">
                                                <a><h4>The Addams Family 2</h4></a>
                                            </div>
                                        </div> 
										
										<div class="nft_coll style-2">
                                            <div class="nft_wrap">
                                                <a><img src="https://iptv.polygium.com/images/movie/2.jpg" class="lazy img-fluid" alt=""/></a>
                                            </div>
                                            <div class="nft_coll_info">
                                                <a><h4>Ben & Jody</h4></a>
                                            </div>
                                        </div>
										
										<div class="nft_coll style-2">
                                            <div class="nft_wrap">
                                                <a><img src="https://iptv.polygium.com/images/movie/3.jpg" class="lazy img-fluid" alt=""/></a>
                                            </div>
                                            <div class="nft_coll_info">
                                                <a><h4>Just Mom</h4></a>
                                            </div>
                                        </div>
										
										<div class="nft_coll style-2">
                                            <div class="nft_wrap">
                                                <a><img src="https://iptv.polygium.com/images/movie/4.jpg" class="lazy img-fluid" alt=""/></a>
                                            </div>
                                            <div class="nft_coll_info">
                                                <a><h4>Nightmare Alley</h4></a>
                                            </div>
                                        </div>
										
										<div class="nft_coll style-2">
                                            <div class="nft_wrap">
                                                <a><img src="https://iptv.polygium.com/images/movie/5.jpg" class="lazy img-fluid" alt=""/></a>
                                            </div>
                                            <div class="nft_coll_info">
                                                <a><h4>Kamu Tidak Sendiri</h4></a>
                                            </div>
                                        </div>
										
										<div class="nft_coll style-2">
                                            <div class="nft_wrap">
                                                <a><img src="https://iptv.polygium.com/images/movie/6.jpg" class="lazy img-fluid" alt=""/></a>
                                            </div>
                                            <div class="nft_coll_info">
                                                <a><h4>Copshop</h4></a>
                                            </div>
                                        </div>
										
										<div class="nft_coll style-2">
                                            <div class="nft_wrap">
                                                <a><img src="https://iptv.polygium.com/images/movie/7.jpg" class="lazy img-fluid" alt=""/></a>
                                            </div>
                                            <div class="nft_coll_info">
                                                <a><h4>Scream</h4></a>
                                            </div>
                                        </div>
										
										<div class="nft_coll style-2">
                                            <div class="nft_wrap">
                                                <a><img src="https://iptv.polygium.com/images/movie/8.jpg" class="lazy img-fluid" alt=""/></a>
                                            </div>
                                            <div class="nft_coll_info">
                                                <a><h4>Resident Evil: Welcome to Raccoon City</h4></a>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>



                        </div>
            </section>

            <footer class="footer-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Polygium NFTs</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs Collect</a></li>
                                <li><a href="https://nft.polygium.com/explore">NFTs Explore</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Services</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs</a></li>
                                <li><a href="https://iptv.polygium.com">IPTV</a></li>
                                <li><a href="https://polygium.com/vpn">VPN</a></li>
                                <li><a href="https://storage.polygium.com">FILES</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i> Facebook</a></li>
                                <li><a href="https://twitter.com/polygium/"><i class="fa fa-twitter fa-lg"></i> Twitter</a></li>
                                <li><a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i> Instagram</a></li>
                            </ul>
                        </div>
                    </div>
				<div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
						<h5>About</h5>
                            <p>Projects that are developing with the community for the community.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subfooter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="de-flex">
                                <div class="de-flex-col">
                                    <a href="https://polygium.com">
                                        <span class="copy"> 2022 &copy; <strong><span>Polygium</span></strong></span>
                                    </a>
                                </div>
                                <div class="de-flex-col">
                                    <div class="social-icons">
                                        <a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i></a>
                                        <a><i class="fa fa-twitter fa-lg"></i></a>
                                        <a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


</div>
    
        )
    }
}

export default Iptv;
import React from "react";

class Nft extends React.Component {
    render() {
        return(
    <div>

<header class="transparent scroll-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="de-flex sm-pt10">
                            <div class="de-flex-col">
                                <div class="de-flex-col">
                                    <div id="logo">
                                        <a href="https://polygium.com">
                                            <img alt="" src="assets/images/logo.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="de-flex-col header-col-mid">
                                <ul id="mainmenu">
                                    <li>
                                    <a href="https://nft.polygium.com/explore">Explore<span></span></a>
                                    </li>  									
                                </ul>
                                <div class="menu_side_area">
                                    <span id="menu-btn"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <section id="section-hero" aria-label="section" class="pt20 pb20 vh-100" data-bgimage="url(assets/images/background/10.jpg) bottom">
        <div class="v-center">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="spacer-single"></div>
                        <h6 class="wow fadeInUp" data-wow-delay=".5s"><span class="text-uppercase id-color-2">Polygium NFTs</span></h6>
                        <div class="spacer-10"></div>
                        <h1 class="wow fadeInUp" data-wow-delay=".75s">Discover, <span class="id-color-2">Collect</span> Digital NFTs</h1>
                        <p class="wow fadeInUp lead" data-wow-delay="1s">
                        Polygium is the collection NFT </p>
                        <div class="spacer-10"></div>
                        <a href="https://nft.polygium.com/explore" class="btn-main wow fadeInUp lead" data-wow-delay="1.25s">Explore</a>
                        <div class="mb-sm-30"></div>
                    </div>
                    <div class="col-md-6 xs-hide">
                        <div class="d-carousel">
                            <div id="item-carousel-big-type-2" class="owl-carousel wow fadeIn">
                                <div class="nft_pic style-2">                            
                                    <a>
                                        <span class="nft_pic_info">
                                            <span class="nft_pic_title">Quotes</span>
                                            <span class="nft_pic_by">Rizky Raditya</span>
                                        </span>
                                    </a>
                                    <div class="nft_pic_wrap">
                                        <img src="assets/images/carousel/1.jpg" class="lazy img-fluid" alt=""/>
                                    </div>
                                </div>  
                                
                                <div class="nft_pic style-2">                            
                                    <a>
                                        <span class="nft_pic_info">
                                            <span class="nft_pic_title">Quotes</span>
                                            <span class="nft_pic_by">Rizky Raditya</span>
                                        </span>
                                    </a>
                                    <div class="nft_pic_wrap">
                                        <img src="assets/images/carousel/2.jpg" class="lazy img-fluid" alt=""/>
                                    </div>
                                </div>

                                <div class="nft_pic style-2">                            
                                    <a>
                                        <span class="nft_pic_info">
                                            <span class="nft_pic_title">Quotes</span>
                                            <span class="nft_pic_by">Rizky Raditya</span>
                                        </span>
                                    </a>
                                    <div class="nft_pic_wrap">
                                        <img src="assets/images/carousel/3.jpg" class="lazy img-fluid" alt=""/>
                                    </div>
                                </div>

                            </div>
                                <div class="d-arrow-left"><i class="fa fa-angle-left"></i></div>
                                <div class="d-arrow-right"><i class="fa fa-angle-right"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="section-collections" class="pt30 pb30">
    <div class="container">

        <div class="spacer-single"></div>
                
                <div class="row wow fadeIn"> 
                    <div class="col-lg-12">
                        <h2 class="style-2">New Items</h2>
                    </div>

                    
                    <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div class="nft__item style-2">
                            <div class="author_list_pp">
                                <a>                                   
                                    <img class="lazy" src="images/author/rizky.jpg" alt=""/>
                                    <i class="fa fa-check"></i>
                                </a>
                            </div>
                            <div class="nft__item_wrap">
                                <a>
                                    <img src="images/collections/Doggy.png" class="lazy nft__item_preview" alt=""/>
                                </a>
                            </div>
                            <div class="nft__item_info">
                                <a>
                                    <h4>Doggy ART</h4>
                                </a>
                                <div class="nft__item_action">
                                    <a>details</a>
                                </div>                            
                            </div> 
                        </div>
                    </div>                 
                    
                    <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div class="nft__item style-2">
                            <div class="author_list_pp">
                                <a>                                   
                                    <img class="lazy" src="images/author/rizky.jpg" alt=""/>
                                    <i class="fa fa-check"></i>
                                </a>
                            </div>
                            <div class="nft__item_wrap">
                                <a>
                                    <img src="images/collections/Rest.png" class="lazy nft__item_preview" alt=""/>
                                </a>
                            </div>
                            <div class="nft__item_info">
                                <a>
                                    <h4>Rest ART</h4>
                                </a>
                                <div class="nft__item_action">
                                    <a>details</a>
                                </div>                            
                            </div> 
                        </div>
                    </div> 
                    
                    <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div class="nft__item style-2">
                            <div class="author_list_pp">
                                <a>                                   
                                    <img class="lazy" src="images/author/rizky.jpg" alt=""/>
                                    <i class="fa fa-check"></i>
                                </a>
                            </div>
                            <div class="nft__item_wrap">
                                <a>
                                    <img src="images/collections/Squat.png" class="lazy nft__item_preview" alt=""/>
                                </a>
                            </div>
                            <div class="nft__item_info">
                                <a>
                                    <h4>Squat ART</h4>
                                </a>
                                <div class="nft__item_action">
                                    <a>details</a>
                                </div>                            
                            </div> 
                        </div>
                    </div> 
                    
                    <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div class="nft__item style-2">
                            <div class="author_list_pp">
                                <a>                                   
                                    <img class="lazy" src="images/author/rizky.jpg" alt=""/>
                                    <i class="fa fa-check"></i>
                                </a>
                            </div>
                            <div class="nft__item_wrap">
                                <a>
                                    <img src="images/collections/White%20Red.png" class="lazy nft__item_preview" alt=""/>
                                </a>
                            </div>
                            <div class="nft__item_info">
                                <a>
                                    <h4>White Red</h4>
                                </a>
                                <div class="nft__item_action">
                                    <a>details</a>
                                </div>                            
                            </div> 
                        </div>
                    </div> 
                    </div>
                </div>
            </section>	

        <div class="spacer-single"></div>
    
    <section id="section-hero" class="no-bottom" aria-label="section">
    <div class="d-carousel">
        <div id="item-carousel-big" class="owl-carousel wow fadeIn">
            <div class="nft_pic">                            
                <a href="https://opensea.io/">
                    <span class="nft_pic_info">
                    </span>
                </a>
                <div class="nft_pic_wrap">
                    <img src="assets/images/carousel/OpenSea.png" class="lazy img-fluid" alt=""/>
                </div>
            </div>
            
            <div class="nft_pic">                            
                <a href="https://pancakeswap.finance/">
                    <span class="nft_pic_info">
                    </span>
                </a>
                <div class="nft_pic_wrap">
                    <img src="assets/images/carousel/Pancakeswap.png" class="lazy img-fluid" alt=""/>
                </div>
            </div>
            
            <div class="nft_pic">                            
                <a href="https://www.bakeryswap.org/">
                    <span class="nft_pic_info">
                    </span>
                </a>
                <div class="nft_pic_wrap">
                    <img src="assets/images/carousel/Bakeryswap.png" class="lazy img-fluid" alt=""/>
                </div>
            </div>

        </div>
            <div class="d-arrow-left"><i class="fa fa-angle-left"></i></div>
            <div class="d-arrow-right"><i class="fa fa-angle-right"></i></div>
    </div>
</section>

        <div class="spacer-single"></div>
        

        <footer class="footer-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Polygium NFTs</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs Collect</a></li>
                                <li><a href="https://nft.polygium.com/explore">NFTs Explore</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Services</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs</a></li>
                                <li><a href="https://iptv.polygium.com">IPTV</a></li>
                                <li><a href="https://polygium.com/vpn">VPN</a></li>
                                <li><a href="https://storage.polygium.com">FILES</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i> Facebook</a></li>
                                <li><a href="https://twitter.com/polygium/"><i class="fa fa-twitter fa-lg"></i> Twitter</a></li>
                                <li><a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i> Instagram</a></li>
                            </ul>
                        </div>
                    </div>
				<div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
						<h5>About</h5>
                            <p>Projects that are developing with the community for the community.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subfooter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="de-flex">
                                <div class="de-flex-col">
                                    <a href="https://polygium.com">
                                        <span class="copy"> 2022 &copy; <strong><span>Polygium</span></strong></span>
                                    </a>
                                </div>
                                <div class="de-flex-col">
                                    <div class="social-icons">
                                        <a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i></a>
                                        <a><i class="fa fa-twitter fa-lg"></i></a>
                                        <a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


</div>
    
        )
    }
}

export default Nft;
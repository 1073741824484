import React from "react";

class Doh extends React.Component {
    render() {
        return(
    <div>

<header class="transparent scroll-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="de-flex sm-pt10">
                            <div class="de-flex-col">
                                <div class="de-flex-col">
                                    <div id="logo">
                                        <a href="https://polygium.com">
                                            <img alt="" src="https://polygium.com/vpn/assets/images/logo.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="de-flex-col header-col-mid">
                                <ul id="mainmenu">
                                    <li>
                                    <a href="https://polygium.com/vpn/doh">DNS over HTTPS<span></span></a>
                                    </li>  									
                                </ul>
                                <div class="menu_side_area">
                                    <span id="menu-btn"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <section id="section-hero" aria-label="section" class="pt20 pb20 vh-100" data-bgimage="url(https://polygium.com/vpn/assets/images/background/11.jpg) top">
                <div id="particles-js"></div>
                <div class="v-center">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="spacer-single"></div>
                                <h6 class="wow fadeInUp" data-wow-delay=".5s"><span class="text-uppercase id-color-2">Polygium</span></h6>
                                <div class="spacer-10"></div>
                                <h1 class="wow fadeInUp" data-wow-delay=".75s">DNS over HTTPS</h1>
                                <p class="wow fadeInUp lead" data-wow-delay="1s">
                                HTTPS resolver for privacy, performance and security.</p>
                                <div class="spacer-10"></div>
                                <a href="#configuration" class="btn-main wow fadeInUp lead" data-wow-delay="1.25s">Browser Configuration</a>                             
                                <div class="row">
                                    <div class="spacer-single"></div>
 
                                </div>
                            </div>
                            <div class="col-md-6 xs-hide">
                                <img src="assets/images/shape/logo-light.png" class="img-fluid wow fadeInUp" data-wow-delay=".75s" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
            <div class="container">
                <div class="row sequence">
                <div class="text-center">
             <h2>DNS Public Server</h2>
             <div class="small-border bg-color-2"></div>
             </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 sq-item wow">
                                            <div class="pricing-s1 mb30">
                                                <div class="bottom">

                                                    <ul>
                                                        <li><i class="fa fa-check"></i>AdGuard: https://dns.adguard.com/dns-query</li>
                                                        <li><i class="fa fa-check"></i>OpenDNS: https://doh.opendns.com/dns-query</li>
                                                        <li><i class="fa fa-check"></i>CleanBrowsing: https://doh.cleanbrowsing.org/doh/family-filter</li>
                                                        <li><i class="fa fa-check"></i>Cloudflare: https://cloudflare-dns.com/dns-query</li>
                                                        <li><i class="fa fa-check"></i>dnswarden: https://doh.asia.dnswarden.com/adblock</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 sq-item wow">
                                            <div class="pricing-s1 mb30">
                                                <div class="bottom">
                                                    <ul>
                                                        <li><i class="fa fa-check"></i>Google: https://dns.google/dns-query</li>
                                                        <li><i class="fa fa-check"></i>Comcast: https://doh.xfinity.com/dns-query</li>
                                                        <li><i class="fa fa-check"></i>Quad9: https://dns.quad9.net/dns-query</li>
                                                        <li><i class="fa fa-check"></i>Tiarap: https://doh.tiar.app/dns-query</li>
                                                        <li><i class="fa fa-check"></i>SWITCH: https://dns.switch.ch/dns-query</li>
                                                        <li><i class="fa fa-check"></i>LibreDNS: https://doh.libredns.gr/dns-query</li>
                                                    </ul>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 sq-item wow">
                                            <div class="pricing-s1 mb30">
                                                <div class="bottom">
                                                    <ul>
                                                        <li><i class="fa fa-check"></i>Hurricane Electric: https://ordns.he.net/dns-query</li>
                                                        <li><i class="fa fa-check"></i>Hostux.net: https://dns.hostux.net/dns-query</li>
                                                        <li><i class="fa fa-check"></i>Foundation for Applied Privacy: https://doh.applied-privacy.net/query</li>
                                                        <li><i class="fa fa-check"></i>DNSlify: https://doh.dnslify.com/dns-query</li>
                                                        <li><i class="fa fa-check"></i>DNS.SB: https://doh.dns.sb/dns-query</li>
                                                        <li><i class="fa fa-check"></i>CZ.NIC: https://odvr.nic.cz/dns-query</li>
                                                    </ul>
                                                </div>
                                                
                                            </div>
                                        </div>

                                    </div>

                    </div>
            </section>

            <section aria-label="section" id="configuration">
			<div class="container">
			<div class="row">
            <div class="text-center">
             <h2>Browser Configuration</h2>
             <div class="small-border bg-color-2"></div>
             </div>
					<div class="col-md-6 mb-sm-30">
                            <div class="accordion" id="accordion1">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="heading1">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                   Chrome
                                  </button>
                                </h2>
                                <div id="collapse1" class="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion1">
                                  <div class="accordion-body">
                                  Browser Settings Menu - Privacy and security - Security - Advanced section and set Custom URL as (DNS Server)
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="heading2">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                    Edge
                                  </button>
                                </h2>
                                <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion1">
                                  <div class="accordion-body">
                                   Browser Settings Menu - Privacy, search, and services - Security section and set Custom URL as (DNS Server)
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="heading3">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                    Firefox
                                  </button>
                                </h2>
                                <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion1">
                                  <div class="accordion-body">
                                   Browser Settings Menu - Options - General Tab - Network Settings - Settings section and set Custom URL as (DNS Server) and click OK. 
                                  </div>
                                </div>
                              </div>
                            </div>

						</div>

						<div class="col-md-6 mb-sm-30">
                            <div class="accordion" id="accordionExample">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Brave
                                  </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                  <div class="accordion-body">
                                  Browser Settings Menu - Additional settings - Privacy and security - Security - Advanced section and set Custom URL as (DNS Server)
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  Android Phones
                                  </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                  <div class="accordion-body">
                                    For Android, follow the details for your browser as covered in the guidelines above.
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  iPhones & Safari
                                  </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                  <div class="accordion-body">
                                   Download this DNS profile. Install the profile in Safari under Settings - General - Profile.
                                  </div>
                                </div>
                              </div>
                            </div>

						</div>

					</div>

				</div>
			</section>

            <div class="spacer-double"></div>
       

        <footer class="footer-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Polygium NFTs</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs Collect</a></li>
                                <li><a href="https://nft.polygium.com/explore">NFTs Explore</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Services</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs</a></li>
                                <li><a href="https://iptv.polygium.com">IPTV</a></li>
                                <li><a href="https://polygium.com/vpn">VPN</a></li>
                                <li><a href="https://storage.polygium.com">FILES</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i> Facebook</a></li>
                                <li><a href="https://twitter.com/polygium/"><i class="fa fa-twitter fa-lg"></i> Twitter</a></li>
                                <li><a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i> Instagram</a></li>
                            </ul>
                        </div>
                    </div>
				<div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
						<h5>About</h5>
                            <p>Projects that are developing with the community for the community.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subfooter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="de-flex">
                                <div class="de-flex-col">
                                    <a href="https://polygium.com">
                                        <span class="copy"> 2022 &copy; <strong><span>Polygium</span></strong></span>
                                    </a>
                                </div>
                                <div class="de-flex-col">
                                    <div class="social-icons">
                                        <a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i></a>
                                        <a><i class="fa fa-twitter fa-lg"></i></a>
                                        <a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


</div>
    
        )
    }
}

export default Doh;
import React from "react";

class Hotspot extends React.Component {
    render() {
        return(


<div class="nk-wrap">
        <main class="nk-pages">


            <section class="section bg-white bg-blend-light-alt" id="features">
                    <div class="container">
                        <div class="nk-block nk-block-feature">
                            <div class="section-head text-center wide-auto-sm">
                                <div class="section-head-line">
                                    <span class="line-1"></span><span class="line-2"></span><span class="line-3"></span><span class="line-4"></span>
                                     <span class="line-5"></span><span class="line-6"></span><span class="line-7"></span><span class="line-8"></span>
                                </div>
                                <h2 class="title title-s5 animated" data-animate="fadeInUp" data-delay=".1">Features</h2>
                                <p class="animated" data-animate="fadeInUp" data-delay=".2">Protect yourself and your data online. Securely access apps, websites, entertainment, and more.</p>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-mb-10">
                                    <div class="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap">
                                        <div class="feature-text">
                                            <h5 class="title title-md">Secure Internet</h5>
                                            <p>Keep all your data safe by connecting to a Sixward hotspot.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-mb-10">
                                    <div class="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap">
                                        <div class="feature-text">
                                            <h5 class="title title-md">No-log Policy</h5>
                                            <p>We do not track your browsing, collect or share your personal data.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-mb-10">
                                    <div class="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap">
                                        <div class="feature-text">
                                            <h5 class="title title-md">Universal Gateway</h5>
                                            <p>Unrestricted gateway that allows users to access any content connect on the wifi network.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-mb-10">
                                    <div class="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap">
                                        <div class="feature-text">
                                            <h5 class="title title-md">Split DNS Server</h5>
                                            <p>Split dns is an advanced feature that allows you to access the internet directly and maintain two connections at the same time</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-mb-10">
                                    <div class="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap">
                                        <div class="feature-text">
                                            <h5 class="title title-md">Prevent DNS Leaks</h5>
                                            <p>When you connect to Sixward Hotspot, all your online traffic is routed through encrypted DNS and without any DNS Leaks owned by internet service providers (ISP)</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-mb-10">
                                    <div class="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap">
                                        <div class="feature-text">
                                            <h5 class="title title-md">Private DNS</h5>
                                            <p>By encrypting all of your online traffic, Sixward Hotspot shields your DNS requests from third parties. This means no one can spy on you or secretly redirect you to a malicious site.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section">
                    <div class="container">
                        <div class="nk-block nk-block-text-wrap">
                            <div class="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
                                <div class="col-mb-9 col-sm-7 col-md-6">
                                    <div class="nk-block-img animated">
                                        <div class="gfx-round-bg"></div>
                                        <img src="images/light/private-dns-rocket.svg" alt="privatedns"/>
                                    </div>
                                </div>
                                <div class="col-sm-10 col-md-6 col-lg-5">
                                    <div class="nk-block-text">
                                        <h2 class="title title-regular">Faster DNS Processing</h2>
                                        <p>We are constantly optimizing our network for speed and security. This likely means faster DNS response times where it matters, like gaming.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section class="section">
                    <div class="container">
                        <div class="nk-block nk-block-text-wrap">
                            <div class="row align-items-center gutter-vr-30px justify-content-lg-between justify-content-center">
                                <div class="col-lg-5 col-mb-10 order-lg-last">
                                    <div class="nk-block-img gfx-re-lgr py-5 py-lg-0">
                                        <div class="gfx-round-bg"></div>
                                        <img src="images/light/public-wifi.svg" alt="hotspot"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-mb-10">
                                    <div class="nk-block-text">
                                        <h2 class="title title-regular">Experience</h2>
                                        <p>Bye-bye buffering. Enjoy your Streaming time anywhere, anytime, uninterrupted</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section class="section bg-white bg-blend-light-alt" id="unstoppable">
                <div class="container">
                    <div class="section-head text-center wide-auto-sm">

                        <h2 class="title title-s4 animated" data-animate="fadeInUp" data-delay=".1">Unstoppable</h2>
                        <p class="animated" data-animate="fadeInUp" data-delay=".2">Projects that are developing with the community for the community</p>
                    </div>
                    <div class="nk-block nk-block-text-wrap">
                        <div class="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
                            <div class="col-mb-9 col-sm-7 col-md-6">
                                <div class="nk-block-img animated" data-animate="fadeInUp" data-delay=".3">
                                    <img src="images/light/devices-secure-home.svg" alt="unstoppable"/>
                                </div>
                            </div>
                            <div class="col-sm-10 col-md-6 col-lg-5">
                                <div class="nk-block-text ms-lg-3 ms-xl-0">
                                    <p class="animated" data-animate="fadeInUp" data-delay=".4">Sixward is a peer to peer digital project connected on a decentralized platform.</p>
                                    <ul class="list list-dot pdb-r">
                                        <li class="animated" data-animate="fadeInUp" data-delay=".5">Experience</li>
                                        <li class="animated" data-animate="fadeInUp" data-delay=".55">Build</li>
                                        <li class="animated" data-animate="fadeInUp" data-delay=".65">Collect</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <footer class="nk-footer bg-light section-connect">

            <div class="section section-footer section-s bg-transparent">
                <div class="container">
                    <div class="nk-block block-footer">
                        <div class="row">
                            <div class="col">
                                <div class="wgs wgs-text text-center mb-3">
                                    <ul class="social pdb-m justify-content-center">
                                        <li><a href="https://www.facebook.com/sixward"><em class="social-icon fab fa-facebook-f"></em></a></li>
                                        <li><a href="https://twitter.com/sixward"><em class="social-icon fab fa-twitter"></em></a></li>
                                        <li><a href="https://www.instagram.com/sixward"><em class="social-icon fab fa-instagram"></em></a></li>
                                    </ul>
                                    <div class="copyright-text copyright-text-s3 pdt-m">
                                        <p><span class="d-sm-block">2022 &copy; <a href="https://www.sixward.my.id">Sixward</a></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>


)
}
}

export default Hotspot;
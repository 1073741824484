import React from "react";

class Channel extends React.Component {
    render() {
        return(
    <div>

<header class="transparent scroll-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="de-flex sm-pt10">
                            <div class="de-flex-col">
                                <div class="de-flex-col">
                                    <div id="logo">
                                        <a href="https://polygium.com">
                                            <img alt="" src="https://iptv.polygium.com/assets/images/logo.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="de-flex-col header-col-mid">
                                <ul id="mainmenu">
                                    <li>
                                        <a href="https://iptv.polygium.com/channel">CHANNEL TV<span></span></a>
                                    </li> 
                                    <li>
                                        <a href="https://iptv.polygium.com/movies">MOVIES<span></span></a>
                                    </li> 									
                                </ul>
                                <div class="menu_side_area">
                                    <span id="menu-btn"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <section aria-label="section">
                <div class="container">
                    <div class="row wow fadeIn">
                        <div class="col-lg-12">

                            <div class="items_filter">

                                <div id="item_category" class="dropdown">
                                    <a class="btn-selector">Channel TV</a>
                                </div>

                            </div>
                        </div>                     
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/rcti.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/rcti.png">
                                        <h4>RCTI</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>                 
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/gtv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/gtv.png">
                                        <h4>GLOBAL TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div> 
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/inews.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/inews.png">
                                        <h4>iNews</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div> 
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/tvone.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/tvone.png">
                                        <h4>TV One</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/transtv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/transtv.png">
                                        <h4>Trans TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/mnctv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/mnctv.png">
                                        <h4>MNC TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/metrotv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/metrotv.png">
                                        <h4>Metro TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						 
						<div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/trans7.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/trans7.png">
                                        <h4>Trans 7</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						 
						                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/antv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/antv.png">
                                        <h4>Antv</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						 
						                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/tvri.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/tvri.png">
                                        <h4>Tvri</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/nickjr.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/nickjr.png">
                                        <h4>Nick Jr.</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/miaomi.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/miaomi.png">
                                        <h4>Miao Mi</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/ent.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/ent.png">
                                        <h4>ENTERTAINMENT</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						  
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/fightsport.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/fightsport.png">
                                        <h4>Fight Sports</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/infotainment.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/infotainment.png">
                                        <h4>Infotainment</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						  
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/fmn.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/fmn.png">
                                        <h4>FMN</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/babytv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/babytv.png">
                                        <h4>Baby TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						 
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/cartoonnetwork.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/cartoonnetwork.png">
                                        <h4>Cartoon Network</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						 
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/animalplanet.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/animalplanet.png">
                                        <h4>Animal Planet</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						 
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/afn.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/afn.png">
                                        <h4>Asian Food Network</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/kidstv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/kidstv.png">
                                        <h4>Kids TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/nick.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/nick.png">
                                        <h4>Nick</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						 
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/nationalgeographic.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/nationalgeographic.png">
                                        <h4>National Geographic</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						 
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/cnbc.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/cnbc.png">
                                        <h4>CNBC</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/life.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/life.png">
                                        <h4>Life</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/hits.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/hits.png">
                                        <h4>HITS</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
								
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/warnertv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/warnertv.png">
                                        <h4>Warner TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/crimeinvestigation.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/crimeinvestigation.png">
                                        <h4>Crime Investigation</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/muslimtv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/muslimtv.png">
                                        <h4>Muslim TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/musictv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/musictv.png">
                                        <h4>Music TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/mncnews.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/mncnews.png">
                                        <h4>MNC News</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/mncsports.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/mncsports.png">
                                        <h4>MNC Sports</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/idxchannel.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/idxchannel.png">
                                        <h4>IDX Channel</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/abcaustralia.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/abcaustralia.png">
                                        <h4>ABC Australia</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/aljazeera.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/aljazeera.png">
                                        <h4>Aljazeera English</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/cna.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/cna.png">
                                        <h4>Channel News Asia</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/lifetime.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/lifetime.png">
                                        <h4>Lifetime</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/axn.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/axn.png">
                                        <h4>AXN</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/animax.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/animax.png">
                                        <h4>Animax</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/one.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/one.png">
                                        <h4>One</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/natgeowild.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/natgeowild.png">
                                        <h4>Nat Geo Wild</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/mshopsupersale.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/mshopsupersale.png">
                                        <h4>MShop Super Sale</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/france24.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/france24.png">
                                        <h4>France 24</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/discovery.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/discovery.png">
                                        <h4>Discovery Channel</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/history.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/history.png">
                                        <h4>History</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/beinsports1.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/beinsports1.png">
                                        <h4>Bein Sports 1</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/beinsports3.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/beinsports3.png">
                                        <h4>Bein Sports 3</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/cnn.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/cnn.png">
                                        <h4>CNN</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/soccerchannel.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/soccerchannel.png">
                                        <h4>Soccer Channel</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/foxnewschannel.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/foxnewschannel.png">
                                        <h4>Fox News Channel</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/oktv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/oktv.png">
                                        <h4>OK TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/thrill.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/thrill.png">
                                        <h4>Thrill</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/celestialmovie.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/celestialmovie.png">
                                        <h4>Celestial Movies</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/arirang.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/arirang.png">
                                        <h4>Arirang</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/zeebioskop.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/zeebioskop.png">
                                        <h4>Zee Bioskop</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/dw.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/dw.png">
                                        <h4>DW</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/bbcearth.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/bbcearth.png">
                                        <h4>BBC Earth</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/bbcworldnews.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/bbcworldnews.png">
                                        <h4>BBC World News</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/beritasatu.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/beritasatu.png">
                                        <h4>Berita Satu</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/bloomberg.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/bloomberg.png">
                                        <h4>Bloomberg</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/cbeebies.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/cbeebies.png">
                                        <h4>CBeebies</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
						
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/ccm.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/ccm.png">
                                        <h4>Celestial Classic Movie</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
							
                        <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item style-2">
                                <div class="nft__item_wrap">
                                    <a>
                                        <img src="https://iptv.polygium.com/images/channel/jaktv.png" class="lazy nft__item_preview" alt=""/>
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="https://iptv.polygium.com/images/channel/jaktv.png">
                                        <h4>Jak TV</h4>
                                    </a>
                           
                                </div> 
                            </div>
                        </div>
													
						
               
                        <div class="col-md-12 text-center">
                            <a href="#" id="loadmore" class="btn-main wow fadeInUp lead">Load more</a>
                        </div>                                              
                    </div>
                </div>
            </section>

            <footer class="footer-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Polygium NFTs</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs Collect</a></li>
                                <li><a href="https://nft.polygium.com/explore">NFTs Explore</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Services</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs</a></li>
                                <li><a href="https://iptv.polygium.com">IPTV</a></li>
                                <li><a href="https://polygium.com/vpn">VPN</a></li>
                                <li><a href="https://storage.polygium.com">FILES</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i> Facebook</a></li>
                                <li><a href="https://twitter.com/polygium/"><i class="fa fa-twitter fa-lg"></i> Twitter</a></li>
                                <li><a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i> Instagram</a></li>
                            </ul>
                        </div>
                    </div>
				<div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
						<h5>About</h5>
                            <p>Projects that are developing with the community for the community.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subfooter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="de-flex">
                                <div class="de-flex-col">
                                    <a href="https://polygium.com">
                                        <span class="copy"> 2022 &copy; <strong><span>Polygium</span></strong></span>
                                    </a>
                                </div>
                                <div class="de-flex-col">
                                    <div class="social-icons">
                                        <a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i></a>
                                        <a><i class="fa fa-twitter fa-lg"></i></a>
                                        <a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


</div>
    
        )
    }
}

export default Channel;
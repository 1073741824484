import Home from './pages/home';
import './App.css';
import Channel from './pages/channel';
import Movies from './pages/movies';
import Nft from './pages/nft';
import Explore from './pages/explore';
import Storage from './pages/storage';
import Vpn from './pages/vpn';
import Doh from './pages/doh';
import Iptv from './pages/iptv';
import Page404 from './pages/page404';
import Sixward from './pages/sixward';
import Hotspot from './pages/hotspot';

function App() {
  return (
  <Sixward />
  );
}


export default App;

import React from "react";

class Header extends React.Component {
    render() {
        return(

            <header class="nk-header page-header is-sticky is-shrink is-transparent is-light" id="header">
            <div class="header-main">
                <div class="header-container container">
                    <div class="header-wrap">
                        <div class="header-logo logo animated" data-animate="fadeInDown" data-delay=".65">
                            <a href="./" class="logo-link">
                                <img class="logo-dark" src="images/logo.png" srcset="images/logo2x.png 2x" alt="logo"/>
                                <img class="logo-light" src="images/logo.png" srcset="images/logo2x.png 2x" alt="logo"/>
                            </a>
                        </div>
                        <div class="header-nav-toggle">
                            <a href="#" class="navbar-toggle" data-menu-toggle="header-menu">
                                <div class="toggle-line">
                                    <span></span>
                                </div>
                            </a>
                        </div>
                        <div class="header-navbar header-navbar-s1">
                            <nav class="header-menu" id="header-menu">
                                <ul class="menu animated" data-animate="fadeInDown" data-delay=".75">
                                    <li class="menu-item"><a class="menu-link nav-link" href="#about">About</a></li>
                                    <li class="menu-item"><a class="menu-link nav-link" href="#why">Why</a></li>
                                    <li class="menu-item"><a class="menu-link nav-link" href="#benifits">Benifits</a></li>
                                    <li class="menu-item"><a class="menu-link nav-link" href="#token">Token Sale</a></li>
                                    <li class="menu-item"><a class="menu-link nav-link" href="#roadmap">Roadmap</a></li>
                                    <li class="menu-item"><a class="menu-link nav-link" href="#team">Team</a></li>
                                    <li class="menu-item"><a class="menu-link nav-link" href="#faqs">Faqs</a></li>
                                    <li class="menu-item"><a class="menu-link nav-link" href="#contact">Contact</a></li>
                                </ul>
                                <ul class="menu-btns menu-btns-s3 align-items-center animated" data-animate="fadeInDown" data-delay=".85">
                                    <li class="language-switcher language-switcher-s1 toggle-wrap">
                                        <a class="toggle-tigger" href="#">English</a>
                                        <ul class="toggle-class toggle-drop toggle-drop-left drop-list drop-list-sm">
                                            <li><a href="#">French</a></li>
                                            <li><a href="#">Chinese</a></li>
                                            <li><a href="#">Hindi</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#" data-bs-toggle="modal" data-bs-target="#register-popup" class="btn btn-md btn-primary btn-outline"><span>Join Our Wishlist</span></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-banner bg-light ov-h header-banner-jasmine">
                <div class="background-shape"></div>
                <div class="nk-banner">
                    <div class="banner banner-fs banner-single">
                        <div class="nk-block nk-block-header my-auto">
                            <div class="container">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-lg-9 text-center">
                                        <div class="banner-caption">
                                            <div class="cpn-head">
                                                <h1 class="title title-xl-2 animated" data-animate="fadeInUp" data-delay="1.25">Polygium</h1>
                                            </div>
                                            <div class="cpn-text cpn-text-s2">
                                                <p class="lead-s2 animated" data-animate="fadeInUp" data-delay="1.35">Peer-to-peer platform</p>
                                            </div>
                                            <div class="cpn-action">
                                                <ul class="cpn-links animated" data-animate="fadeInUp" data-delay="1.45">
                                                    <li><a class="link link-primary" href="#"><em class="link-icon icon-circle ti ti-files"></em> <span>White Paper</span></a></li>
                                                    <li><a class="link link-primary" href="#"><em class="link-icon icon-circle ti ti-file"></em> <span>One Pager</span></a></li>
                                                    <li><a class="link link-primary video-play" href="https://www.youtube.com/watch?v=SSo_EIwHSd4"><em class="link-icon icon-circle ti ti-control-play"></em> <span>How it works</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="nk-block nk-block-status">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8">
                                        <div class="row justify-content-center">
                                            <div class="col-md-8">
                                                <div class="token-status token-status-s4">
                                                    <div class="token-box token-box-s2 bg-transparent animated" data-animate="fadeInUp" data-delay="1.65">
                                                        <h4 class="title title-xs-alt tc-default">PRE SALE STARTING IN</h4>
                                                        <div class="countdown-s3 countdown-s4 countdown" data-date="2022/06/11"></div>
                                                    </div>
                                                    <div class="token-action token-action-s1 animated" data-animate="fadeInUp" data-delay="1.65">
                                                        <a class="btn btn-rg btn-grad btn-grad-alt" href="#">Sign UP &amp; Join our PRe-Sale list</a>
                                                    </div>
                                                    <ul class="icon-list animated" data-animate="fadeInUp" data-delay="1.65">
                                                        <li><em class="fab fa-bitcoin"></em></li>
                                                        <li><em class="fas fa-won-sign"></em></li>
                                                        <li><em class="fab fa-cc-visa"></em></li>
                                                        <li><em class="fab fa-cc-mastercard"></em></li>
                                                    </ul>
                                                    <div class="circle-animation animated" data-animate="fadeIn" data-delay="1.55">
                                                        <div class="circle-animation-l1 ca">
                                                            <span class="circle-animation-l1-d1 ca-dot ca-color-1"></span>
                                                            <span class="circle-animation-l1-d2 ca-dot ca-color-2"></span>
                                                            <span class="circle-animation-l1-d3 ca-dot ca-color-3"></span>
                                                            <span class="circle-animation-l1-d4 ca-dot ca-color-1"></span>
                                                            <span class="circle-animation-l1-d5 ca-dot ca-color-2"></span>
                                                            <span class="circle-animation-l1-d6 ca-dot ca-color-3"></span>
                                                        </div>
                                                        <div class="circle-animation-l2 ca">
                                                            <span class="circle-animation-l2-d1 ca-dot ca-color-1"></span>
                                                            <span class="circle-animation-l2-d2 ca-dot ca-color-3"></span>
                                                            <span class="circle-animation-l2-d3 ca-dot ca-color-2"></span>
                                                            <span class="circle-animation-l2-d4 ca-dot ca-color-1"></span>
                                                            <span class="circle-animation-l2-d5 ca-dot ca-color-2"></span>
                                                        </div>
                                                        <div class="circle-animation-l3 ca">
                                                            <span class="circle-animation-l3-d1 ca-dot ca-color-1"></span>
                                                            <span class="circle-animation-l3-d2 ca-dot ca-color-3"></span>
                                                            <span class="circle-animation-l3-d3 ca-dot ca-color-2"></span>
                                                            <span class="circle-animation-l3-d4 ca-dot ca-color-1"></span>
                                                            <span class="circle-animation-l3-d5 ca-dot ca-color-2"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="particles-bg" class="particles-container particles-bg" data-pt-base="#00c0fa" data-pt-base-op=".3" data-pt-line="#2b56f5" data-pt-line-op=".5" data-pt-shape="#00c0fa" data-pt-shape-op=".2"></div>
            </div>
        </header>

            )
}
}

export default Header;
import React from "react";

class Storage extends React.Component {
    render() {
        return(
    <div>


        <footer class="footer-light">
      <div class="container">
          <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-1">
                  <div class="widget">
                      <h5>NFTs Marketplace</h5>
                      <ul>
                          <li><a href="https://nft.polygium.com">Polygium NFTs</a></li>
                          <li><a href="https://nft.polygium.com/explore">NFTs Explore</a></li>
                      </ul>
                  </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-1">
                  <div class="widget">
                      <h5>Services</h5>
                      <ul>
                          <li><a href="https://nft.polygium.com">NFTs</a></li>
                          <li><a href="https://iptv.polygium.com">IPTV</a></li>
                          <li><a href="https://polygium.com/vpn">VPN</a></li>
                          <li><a href="https://storage.polygium.com">FILES</a></li>
                      </ul>
                  </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-1">
                  <div class="widget">
                      <h5>Community</h5>
                      <ul>
                          <li><a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i> Facebook</a></li>
                          <li><a href="https://twitter.com/polygium/"><i class="fa fa-twitter fa-lg"></i> Twitter</a></li>
                          <li><a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i> Instagram</a></li>
                      </ul>
                  </div>
              </div>
  <div class="col-md-3 col-sm-6 col-xs-1">
                  <div class="widget">
      <h5>About</h5>
                      <p>Projects that are developing with the community for the community.</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="subfooter">
          <div class="container">
              <div class="row">
                  <div class="col-md-12">
                      <div class="de-flex">
                          <div class="de-flex-col">
                              <a href="https://polygium.com">
                                  <span class="copy"> 2022 &copy; <strong><span>Polygium</span></strong></span>
                              </a>
                          </div>
                          <div class="de-flex-col">
                              <div class="social-icons">
                                  <a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i></a>
                                  <a><i class="fa fa-twitter fa-lg"></i></a>
                                  <a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>

</div>
    
        )
    }
}

export default Storage;
import React from "react";

class Home extends React.Component {
    render() {
        return(
    <div>

  <div class="onloadpage" id="page_loader">
	<div class="pre-content">
	   <div class="logo-pre"><img src="assets/images/logo.png" alt="Logo" class="img-fluid" /></div>
	   <div class="pre-text- text-radius text-light text-animation bg-b">Loading</div>
	</div>
 </div>

 <header class="nav-bg-b main-header navfix fixed-top menu-dark">
	<div class="container-fluid m-pad">
	   <div class="menu-header">
		  <div class="dsk-logo"><a class="nav-brand" href="https://polygium.com">
			 <img src="assets/images/white-logo.png" alt="Logo" class="mega-white-logo"/>
			 <img src="assets/images/logo.png" alt="Logo" class="mega-darks-logo"/>
			 </a>
		  </div>
		  <div class="custom-nav" role="navigation">
			 <ul class="nav-list">
                     <li><a href="#services" class="btn-br bg-btn3 btshad-b2 lnk">BROWSE <span class="circle"></span></a> </li>
			 </ul>
		  </div>

	   </div>
	   
	</div>
 </header>

 <section class="hero-section hero-creative-agenc1"  data-background="assets/images/hero/banner-home.jpg">
		<div class="text-block niwax" data-rellax-speed="-3">
			<div class="container">
				<div class="row justify-content-center text-center">
					<div class="col-lg-9 v-center">
						<div class="header-heading">
							<h1 class="wow fadeInUp text-effect-1" data-wow-delay=".2s"><span class="text-radius text-light text-animation bg-b">Sixward</span></h1>
							<p class="wow fadeInUp" data-wow-delay=".4s">Peer-to-peer platform</p>
							<a href="#services" class="btn-main bg-btn4 lnk wow fadeInUp" data-wow-delay=".6s">BROWSE<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
						</div>
					</div>
				</div>
			</div>
		</div>
 </section>

<section class="dg-service2 pb120 pt120" id="services">
<div class="container">
<div class="row upset">
<div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp" data-wow-delay=".2s">
<div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
<h4 class="mb10">Integrasi</h4>
<p>Sixward is a digital project that includes</p>
</div>
</div>
<div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp" data-wow-delay=".4s">
<div class="service-sec-list srvc-bg-nx srcl1">
<img src="assets/images/icons/service3.svg" alt="service" />
<h5 class="mb10">Streaming Services</h5>
<ul class="-service-list">
<li> <a href="https://iptv.polygium.com/movies">Movies</a> </li>
<li> <a href="https://iptv.polygium.com/channel">Tv</a> </li>
<li> <a href="https://music.polygium.com">Music</a> </li>
</ul>
<p>Project development to improve the watch experience.</p>
</div>
</div>
<div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp" data-wow-delay=".6s">
<div class="service-sec-list srvc-bg-nx srcl2">
<img src="assets/images/icons/service2.svg" alt="service" />
<h5 class="mb10">Web Development</h5>
<ul class="-service-list">
<li> <a href="">Angular.JS</a> </li>
<li> <a href="">Bootstrap</a> </li>
<li> <a href="">jQuery</a> </li>
</ul>
<p>Design and develop creative websites from us.</p>
</div>
</div>
<div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".8s">
<div class="service-sec-list srvc-bg-nx srcl3">
<img src="assets/images/icons/service6.svg" alt="service" />
<h5 class="mb10">Share a Files</h5>
<ul class="-service-list">
<li> <a href="https://storage.polygium.com">Upload Files</a> </li>
</ul>
<p>An open source, highly secure, private and decentralized way to send and share your files.</p>
</div>
</div>
<div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay="1s">
<div class="service-sec-list srvc-bg-nx srcl4">
<img src="assets/images/icons/tech.svg" alt="service" />
<h5 class="mb10">NFTs Development</h5>
<ul class="-service-list">
<li> <a href="https://nft.polygium.com">NFTs </a> </li>
<li> <a href="https://nft.polygium.com/explore">Explore </a> </li>
</ul>
<p>Discover, Collect Digital NFTs.</p>
</div>
</div>
<div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus mt30- wow fadeInUp" data-wow-delay="1.2s">
<div class="service-sec-list srvc-bg-nx srcl5">
<img src="assets/images/icons/seo.svg" alt="service" />
<h5 class="mb10">Data Analysis</h5>
<ul class="-service-list">
<li> <a href="">Analysis</a> </li>
</ul>
<p>develop a project to analyze data.</p>
</div>
</div>
</div>

</div>
</section>

<section class="work-category pad-tb tilt3d">
<div class="container">
<div class="row">
<div class="col-lg-4 v-center">
<div class="common-heading text-l">
<span>Project Development</span>
<h2>Unstoppable</h2>
<p>Projects that are developing with the community for the community</p>
</div>
</div>
<div class="col-lg-8">
<div class="work-card-set">
<div  class="icon-set wow fadeIn" data-wow-delay=".2s">
<div class="work-card cd1">
<div class="icon-bg"><img src="assets/images/icons/icon-1.png" alt="Industries" /></div>
<p>Community</p>
</div>
</div>
<div class="icon-set wow fadeIn" data-wow-delay=".8s">
<div class="work-card cd4">
<div class="icon-bg"><img src="assets/images/icons/icon-4.png" alt="Industries" /></div>
<p>Streaming Service</p>
</div>
</div>
<div  class="icon-set wow fadeIn" data-wow-delay=".4s">
<div class="work-card cd2">
<div class="icon-bg"><img src="assets/images/icons/icon-2.png" alt="Industries" /></div>
<p>Digital Marketing</p>
</div>
</div>
<div class="icon-set wow fadeIn" data-wow-delay="2.4s">
<div class="work-card cd12">
<div class="icon-bg"><img src="assets/images/icons/icon-12.png" alt="Industries" /></div>
<p>P2P Business</p>
</div>
</div>
</div>
</div>
</div>
</div>
</section>

<footer class="dark-footer dg-bg--1 pt60 nshape dark-footer-1">
<div class="footer-row2">
<div class="container">
<div class="row">
<div class="col-lg-3 col-sm-6">
<h5>NFTs Collection</h5>
<ul class="footer-address-list link-hover">
<li><a href="https://nft.polygium.com">Polygium NFTs</a></li>
<li><a href="https://nft.polygium.com/explore">NFTs Explore</a></li>
</ul>
</div>
<div class="col-lg-3 col-sm-6">
<h5>Services</h5>
<ul class="footer-address-list link-hover">
<li><a href="https://nft.polygium.com">NFTs</a></li>
<li><a href="https://iptv.polygium.com">IPTV</a></li>
<li><a href="https://polygium.com/vpn">VPN</a></li>
<li><a href="https://storage.polygium.com">Files</a></li>
</ul>
</div>
<div class="col-lg-3 col-sm-6">
<h5>Community</h5>
<ul class="footer-address-list link-hover">
<li><a href="https://www.facebook.com/polygium"><i class="fab fa-facebook"></i> Facebook</a></li>
<li><a href="https://twitter.com/polygium"><i class="fab fa-twitter"></i> Twitter</a></li>
<li><a href="https://www.instagram.com/polygiumid"><i class="fab fa-instagram"></i> Instagram</a></li>
</ul>
</div>
<div class="col-lg-3 col-sm-6">
<h5>Polygium Labs</h5>
<ul class="footer-address-list link-hover">
<li><a href="https://polygium.com/about">About us</a></li>
<li><a href="https://polygium.com/privacy">Privacy Policy</a></li>
</ul>
</div>
</div>
</div>
</div>
<hr class="hline"></hr>
<div class="footer-row3">
<div class="copyright">
<div class="container">
<div class="row">
<div class="col-lg-12">
<div class="footer-social-media-icons">
<a href="https://www.facebook.com/polygium/" target="blank"><i class="fab fa-facebook"></i></a>
<a href="https://twitter.com/polygium" target="blank"><i class="fab fa-twitter"></i></a>
<a href="https://www.instagram.com/polygiumid/" target="blank"><i class="fab fa-instagram"></i></a>
</div>
<div class="footer-">
<a href="https://polygium.com">
<span class="copy">2022 &copy; <strong><span>Polygium</span></strong></span>
</a>
</div>
</div>
</div>
</div>
</div>
</div>
</footer>

    </div>
        )
    }
}

export default Home;
import React from "react";

class Explore extends React.Component {
    render() {
        return(
    <div>

<header class="transparent scroll-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="de-flex sm-pt10">
                            <div class="de-flex-col">
                                <div class="de-flex-col">
                                    <div id="logo">
                                        <a href="https://polygium.com">
                                            <img alt="" src="https://nft.polygium.com/assets/images/logo.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="de-flex-col header-col-mid">
                                <ul id="mainmenu">
                                    <li>
                                    <a href="https://nft.polygium.com/explore">Explore<span></span></a>
                                    </li>  									
                                </ul>
                                <div class="menu_side_area">
                                    <span id="menu-btn"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    <section aria-label="section">
                <div class="container">
                    <div class="row wow fadeIn">
                        <div class="col-lg-12">

                            <div class="items_filter">

                                <div id="item_category" class="dropdown">
                                    <a class="btn-selector">Explore</a>
                                </div>

                                <div id="items_type" class="dropdown">
                                    <a class="btn-selector">All Items</a>
                                </div>

                            </div>
                        </div>                     
                        
                                <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div class="nft__item style-2">
                                        <div class="author_list_pp">
                                            <a>                                    
                                                <img class="lazy" src="https://nft.polygium.com/images/author/rizky.jpg" alt=""/>
                                                <i class="fa fa-check"></i>
                                            </a>
                                        </div>
                                        <div class="nft__item_wrap">
                                            <a>
                                                <img src="https://nft.polygium.com/images/collections/Doggy.png" class="lazy nft__item_preview" alt=""/>
                                            </a>
                                        </div>
                                        <div class="nft__item_info">
                                            <a>
                                                <h4>Doggy ART</h4>
                                            </a>
                                            <div class="nft__item_action">
                                                <a>NFTs</a>
                                            </div>                            
                                        </div> 
                                    </div>
                                </div>                 
                                
                                <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div class="nft__item style-2">
                                        <div class="author_list_pp">
                                            <a>                                    
                                                <img class="lazy" src="https://nft.polygium.com/images/author/rizky.jpg" alt=""/>
                                                <i class="fa fa-check"></i>
                                            </a>
                                        </div>
                                        <div class="nft__item_wrap">
                                            <a>
                                                <img src="https://nft.polygium.com/images/collections/Rest.png" class="lazy nft__item_preview" alt=""/>
                                            </a>
                                        </div>
                                        <div class="nft__item_info">
                                            <a>
                                                <h4>Rest ART</h4>
                                            </a>
                                            <div class="nft__item_action">
                                                <a>NFTs</a>
                                            </div>                            
                                        </div> 
                                    </div>
                                </div> 
                                
                                <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div class="nft__item style-2">
                                        <div class="author_list_pp">
                                            <a>                                    
                                                <img class="lazy" src="https://nft.polygium.com/images/author/rizky.jpg" alt=""/>
                                                <i class="fa fa-check"></i>
                                            </a>
                                        </div>
                                        <div class="nft__item_wrap">
                                            <a>
                                                <img src="https://nft.polygium.com/images/collections/Squat.png" class="lazy nft__item_preview" alt=""/>
                                            </a>
                                        </div>
                                        <div class="nft__item_info">
                                            <a>
                                                <h4>Squat ART</h4>
                                            </a>
                                            <div class="nft__item_action">
                                                <a>NFTs</a>
                                            </div>                            
                                        </div> 
                                    </div>
                                </div> 
                                
                                <div class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div class="nft__item style-2">
                                        <div class="author_list_pp">
                                            <a>                                    
                                                <img class="lazy" src="https://nft.polygium.com/images/author/rizky.jpg" alt=""/>
                                                <i class="fa fa-check"></i>
                                            </a>
                                        </div>
                                        <div class="nft__item_wrap">
                                            <a>
                                                <img src="https://nft.polygium.com/images/collections/White%20Red.png" class="lazy nft__item_preview" alt=""/>
                                            </a>
                                        </div>
                                        <div class="nft__item_info">
                                            <a>
                                                <h4>White Red</h4>
                                            </a>
                                            <div class="nft__item_action">
                                                <a>NFTs</a>
                                            </div>                            
                                        </div> 
                                    </div>
                                </div> 
               
                        <div class="col-md-12 text-center">
                            <a href="#" id="loadmore" class="btn-main wow fadeInUp lead">Load more</a>
                        </div>                                              
                    </div>
                </div>
            </section>

        <div class="spacer-single"></div>
        

        <footer class="footer-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Polygium NFTs</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs Collect</a></li>
                                <li><a href="https://nft.polygium.com/explore">NFTs Explore</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Services</h5>
                            <ul>
                                <li><a href="https://nft.polygium.com">NFTs</a></li>
                                <li><a href="https://iptv.polygium.com">IPTV</a></li>
                                <li><a href="https://polygium.com/vpn">VPN</a></li>
                                <li><a href="https://storage.polygium.com">FILES</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i> Facebook</a></li>
                                <li><a href="https://twitter.com/polygium/"><i class="fa fa-twitter fa-lg"></i> Twitter</a></li>
                                <li><a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i> Instagram</a></li>
                            </ul>
                        </div>
                    </div>
				<div class="col-md-3 col-sm-6 col-xs-1">
                        <div class="widget">
						<h5>About</h5>
                            <p>Projects that are developing with the community for the community.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subfooter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="de-flex">
                                <div class="de-flex-col">
                                    <a href="https://polygium.com">
                                        <span class="copy"> 2022 &copy; <strong><span>Polygium</span></strong></span>
                                    </a>
                                </div>
                                <div class="de-flex-col">
                                    <div class="social-icons">
                                        <a href="https://www.facebook.com/polygium/"><i class="fa fa-facebook fa-lg"></i></a>
                                        <a><i class="fa fa-twitter fa-lg"></i></a>
                                        <a href="https://www.instagram.com/polygiumid/"><i class="fa fa-instagram fa-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


</div>
    
        )
    }
}

export default Explore;
import React from "react";

class Page404 extends React.Component {
    render() {
        return(
    <div>


 <header class="nav-bg-b main-header navfix fixed-top menu-dark">
	<div class="container-fluid m-pad">
	   <div class="menu-header">
		  <div class="dsk-logo"><a class="nav-brand" href="https://polygium.com">
			 <img src="https://polygium.com/assets/images/white-logo.png" alt="Logo" class="mega-white-logo"/>
			 <img src="https://polygium.com/assets/images/logo.png" alt="Logo" class="mega-darks-logo"/>
			 </a>
		  </div>
		  <div class="custom-nav" role="navigation">
			 <ul class="nav-list">
                     <li><a href="#services" class="btn-br bg-btn3 btshad-b2 lnk">BROWSE <span class="circle"></span></a> </li>
			 </ul>
		  </div>

	   </div>
	   
	</div>
 </header>

 <section class="error bg-gradient pad-tb">
<div class="container">
<div class="row">
<div class="col-md-12 text-center mt50 mb50">
<div class="layer-div">
<div class="error-block">
<h1>Page not Found</h1>
<p>We can't seem to find the page you're looking for…</p>
<div class="images mt20">
<img src="https://polygium.com/assets/images/shape/error-page.png" alt="error page" class="img-fluid"/>
</div>
<a href="https://polygium.com" class="btn-outline">Back to Home</a>
</div>
</div>
</div>
</div>
</div>
</section>

    </div>
        )
    }
}

export default Page404;